import {Auth} from "aws-amplify"

// the flowing functions could be put into libraries
import authoredFetch from "../aws/amplify-fetch"
import awsFetch from "../aws/aws-fetch"
import {transformAmplifyNetworkError} from "../../utils/general-error"

import {
  DEFAULT_BASEURL,
  WORKSPACE_ENDPOINT,
  PERMISSION_ENDPOINT,
} from "./endpoints"

/**
 * @typedef {object} CheckWorkspaceParams
 * @property {string} workspace
 *
 * @param {CheckWorkspaceParams} params
 */
export async function checkWorkspace(params = {}) {
  try {
    return await awsFetch(WORKSPACE_ENDPOINT, {
      baseURL: DEFAULT_BASEURL,
      method: "GET",
      params,
    })
  } catch (err) {
    if (err.response?.status === 400) {
      err.message = "We couldn’t find your workspace."
    }
    throw err
  }
}

/**
 * @param {AuthUser} param0
 */
export const generateUserId = ({workspace, email}) =>
  workspace + "_" + email.toLowerCase()

/**
 * @typedef {object} SignInAccountParams
 * @property {string} workspace
 * @property {string} email
 * @property {string} password
 *
 * @param {SignInAccountParams} param0
 * @returns {Promise<CurrentAuthUser>}
 */
export async function signInAccount({workspace, email, password}) {
  let username = generateUserId({workspace, email})
  let cognitoUser
  try {
    cognitoUser = await Auth.signIn(username, password)
  } catch (err) {
    if (err.message === "Password attempts exceeded") {
      err.message = "You're trying too often. Please try again later."
    } else if (err.code === "NotAuthorizedException") {
      err.message = "That email and password combination is incorrect."
    } else {
      transformAmplifyNetworkError(err)
    }

    throw err
  }

  try {
    await checkPermission({
      workspace,
    })
  } catch (err) {
    // logout user
    await signOut()

    let error = new Error("You don't have the permission")
    error.response = {
      status: 403,
      headers: {},
      statusText: "403",
      data: {
        errorMessage: error.message,
      },
    }
    error.name = "RequestError"
    throw error
  }
  //   await setUser({
  //     email,
  //     workspace,
  //   })

  return {
    cognitoUser,
    user: {
      email,
      workspace,
    },
  }
}

/**
 * @param {{
 *   workspace: string,
 * }} param0
 */
export async function checkPermission({workspace}) {
  const response = await authoredFetch(PERMISSION_ENDPOINT, {
    baseURL: DEFAULT_BASEURL,
    method: "GET",
    params: {
      workspace,
    },
  })

  return response.data
}

/**
 * @typedef {object} ForgotPasswordParams
 * @property {string} workspace
 * @property {string} email
 *
 * @param {ForgotPasswordParams} param0
 */
export async function forgotPassword({workspace, email}) {
  let username = generateUserId({workspace, email})
  try {
    return await Auth.forgotPassword(username)
  } catch (error) {
    transformAmplifyNetworkError(error)
    throw error
  }
}

/**
 * @typedef {object} ResetPasswordParams
 * @property {string} workspace
 * @property {string} email
 * @property {string} verificationCode
 * @property {string} password
 *
 * @param {ResetPasswordParams} param0
 */
export async function resetPassword({
  workspace,
  email,
  verificationCode,
  password,
}) {
  let username = generateUserId({workspace, email})
  try {
    return await Auth.forgotPasswordSubmit(username, verificationCode, password)
  } catch (error) {
    if (error.code === "ExpiredCodeException") {
      error.message = "Invalid verification code provided, please try again."
    } else {
      transformAmplifyNetworkError(error)
    }
    throw error
  }
}

export async function signOut() {
  await Auth.signOut({
    // global: true,
  })
  //   window.localStorage.removeItem(USER_CACHED_KEY)
  return
}

/**
 * @param {{
 *   workspace: string,
 *   email: string,
 *   name: string,
 *   password: string,
 * }} param0
 */
export async function signup({workspace, email, name, password}) {
  const response = await awsFetch(WORKSPACE_ENDPOINT, {
    baseURL: DEFAULT_BASEURL,
    method: "POST",
    data: {
      workspace,
      adminName: name,
      adminEmail: email,
      adminPassword: password,
    },
  })
  return response
}

/** @typedef {import('amazon-cognito-identity-js').CognitoUser} CognitoUser  */

/**
 * @typedef {object} AuthUser
 * @property {string} workspace
 * @property {string} email
 */

/**
 * @typedef {object} CurrentAuthUser
 * @property {AuthUser} user
 * @property {CognitoUser} cognitoUser
 */
