import * as React from "react"
import {ToastContextProvider} from "./toast-context"
import {ToastContainer} from "./toast-ui"

export {
  AlertType,
  ToastReducerActionType,
  useToast,
  useRemove,
  useToastMessage,
  useToastState,
} from "./toast-context"

/**
 *
 * @param {{
 *   ToastUI: import('./types').ToastMessageComponent
 * }} param0
 */
export const ToastContainerRoot = ({children, ...props}) => {
  return (
    <ToastContextProvider>
      {children}
      <ToastContainer {...props} />
    </ToastContextProvider>
  )
}

// export default ToastContainerRoot
