export const REGION = process.env.GATSBY_AWS_PROJECT_REGION // "us-east-1"
export const HOST = process.env.GATSBY_HOST
export const accessKey = process.env.GATSBY_ACCESS_KEY
export const secretAccessKey = process.env.GATSBY_ACCESS_SECRET

export const COGNITO_ENV = {
  aws_project_region: process.env.GATSBY_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.GATSBY_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.GATSBY_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
}
