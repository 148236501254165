import {
  NO_INTERNET_CODE,
  NO_INTERNET_MSG,
  REQUEST_TIMEOUT_CODE,
  REQUEST_TIMEOUT_MSG,
} from "../utils/general-error"
const DEFAULT_TIMEOUT = 30000

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
}

/**
 * // https://github.com/whatwg/fetch/issues/905
 * @param {AbortSignal[]} signals
 */
function anySignal(signals) {
  const controller = new AbortController()

  function onAbort() {
    controller.abort()

    // Cleanup
    for (const signal of signals) {
      signal.removeEventListener("abort", onAbort)
    }
  }

  for (const signal of signals) {
    if (signal.aborted) {
      onAbort()
      break
    }
    signal.addEventListener("abort", onAbort)
  }

  return controller.signal
}

/**
 * @template T
 * @param {string} uri ex: /something/workspace
 * @param {RequestConfig} requestConfig
 * @returns {Promise<FetchResponse<T>>}
 */
export async function bctFetch(uri, requestConfig = {}) {
  const {
    body: requestBody,
    params: requestParams = {},
    timeout: requestTimeout = DEFAULT_TIMEOUT,
    data: requestData,
    baseURL = "",
    method: requestMethod = "GET",
    headers: requestHeaders,
    signal,
    getHeaders = () => {},
    ...fetchConfigs
  } = requestConfig
  let timeout = false

  // timeout fetch abort
  const abortController = new AbortController()
  const timeoutId = setTimeout(() => {
    timeout = true
    abortController.abort()
  }, requestTimeout)

  // compose fetch params and body
  const params = generateQueryParams(requestParams)
  const body = requestBody || JSON.stringify(requestData)

  // compose fetch full URL
  const fetchURL = encodeURI(baseURL + uri) + (params ? `?${params}` : "")

  return fetch(fetchURL, {
    method: requestMethod,
    headers: {
      ...DEFAULT_HEADERS,
      ...requestHeaders,
      ...getHeaders({
        host: getHostFromURL(baseURL) || getHostFromURL(fetchURL),
        method: requestMethod,
        params,
        uri,
        body,
      }),
    },
    ...fetchConfigs,
    body,
    signal: signal
      ? anySignal([abortController.signal, signal])
      : abortController.signal,
  })
    .then(async r => {
      const isJSONContent =
        r.headers.get("content-type").indexOf("application/json") !== -1

      const headers = {}
      if (r.headers) {
        for (const [key, value] of r.headers.entries()) {
          headers[key] = value
        }
      }

      /** @type {FetchResponse<T>} */
      let mockAxiosResponse = {}
      mockAxiosResponse.status = r.status
      mockAxiosResponse.data = isJSONContent ? await r.json() : await r.text()
      mockAxiosResponse.headers = headers
      mockAxiosResponse.statusText = r.status.toString()

      if (r.status >= 200 && r.status < 300) {
        return mockAxiosResponse
      }
      throwRequestError(mockAxiosResponse, requestParams)
    })
    .catch(err => {
      if (navigator?.onLine === false) {
        err.code = NO_INTERNET_CODE
        err.message = NO_INTERNET_MSG
        throw err
      }

      if (err.name === "AbortError" && timeout) {
        const timeoutError = new Error(REQUEST_TIMEOUT_MSG)
        timeoutError.code = REQUEST_TIMEOUT_CODE
        timeoutError.name = REQUEST_TIMEOUT_CODE
        throw timeoutError
      }
      throw err
    })
    .finally(() => {
      // clear timeout when request is final, success or failed
      clearTimeout(timeoutId)
    })
}

/**
 * https://abc.com.tw/ => abc.com.tw
 * @param {string} url
 * @returns {string}
 */
const getHostFromURL = (url = "") => {
  const reg = /^https?:\/\/([^/]+)/
  const match = url.match(reg)
  return match?.[1]
}

/**
 * @param {FetchResponse<any>} mockAxiosResponse
 */
export const throwRequestError = (mockAxiosResponse, requestParams = {}) => {
  /** @type {FetchError} */
  let error = new Error(
    mockAxiosResponse.data.errorMessage ||
      `Request failed with status code ${mockAxiosResponse.status}`,
  )
  error.response = mockAxiosResponse
  error.request = requestParams
  error.name =
    mockAxiosResponse?.data?.code ||
    mockAxiosResponse?.data?.name ||
    "RequestError"

  throw error
}

export default bctFetch

export function generateQueryParams(params = {}) {
  return Object.keys(params)
    .sort()
    .filter(key => typeof params[key] !== "undefined")
    .map(key => key + "=" + fixedEncodeURIComponent(params[key]))
    .join("&")
}

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16)
  })
}

/**
 * @typedef {object} CustomRequestConfig
 * @property {string=} body
 * @property {string=} baseURL
 * @property {Method} method
 * @property {any=} headers
 * @property {(param: {host: string, method: Method, uri: string, params: string, body: string}) => any} getHeaders
 * @property {any=} params
 * @property {any=} data
 * @property {number=} timeout
 * @property {boolean=} debug
 */

/**
 * @template T
 * @typedef {object} FetchResponse
 * @property {number} status
 * @property {string} statusText
 * @property {T} data
 * @property {object} headers
 */

/**
 * @typedef {object} FetchCustomError
 * @property {FetchResponse<any>=} response
 * @property {object} request
 */

/**
 * @typedef {FetchCustomError & Error} FetchError
 */

/**
 * @typedef {import('axios').Method} Method
 * @typedef {RequestInit & CustomRequestConfig} RequestConfig
 */
