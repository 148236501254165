import {isBrowser} from "../utils/is-browser"
import {signOut} from "../lib/smartworks-api/auth"

const USER_CACHED_KEY = process.env.GATSBY_AUTH_USER

/**
 * @return {boolean}
 */
export const isLoggedIn = () => {
  const user = getUser()
  return !!user?.workspace
}

/**
 * @param {AuthUser} user
 */
export const setUser = async user =>
  window.localStorage.setItem(USER_CACHED_KEY, JSON.stringify(user))

/**
 * @return {AuthUser=} user
 */
export const getUser = () =>
  isBrowser() && window.localStorage.getItem(USER_CACHED_KEY)
    ? JSON.parse(window.localStorage.getItem(USER_CACHED_KEY))
    : undefined

export const getWorkspace = () => getUser()?.workspace

export function removeUser() {
  window.localStorage.removeItem(USER_CACHED_KEY)
  return
}

export async function logout() {
  await signOut()
  removeUser()
  window.location.assign("/app/auth/signin")
}

/** @typedef {import('amazon-cognito-identity-js').CognitoUser} CognitoUser  */
/** @typedef {import('../lib/smartworks-api/auth').AuthUser} AuthUser  */
