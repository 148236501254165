import React from "react"

let toastIdCounter = 0

/**
 * @typedef {import('./types').ToastMessage} ToastMessage
 */

export const AlertType = {
  success: "success",
  error: "error",
  warning: "warning",
}

export const ToastReducerActionType = {
  add: "add",
  delete: "delete",
}

/**
 * @typedef {Object} ToastReducerState
 * @property {number} max
 * @property {string[]} ids
 * @property {{[id: string]: ToastMessage}} messagesMap
 */

/** @type {ToastReducerState} */
const initState = {
  max: 5,

  // array of id
  ids: [],

  // sample: { 'id-1': { message: 'the message, id: 'id-1', type: 'warning}}
  messagesMap: {},
}

/** @type {React.Context<ToastReducerState>} */
export const ToastReducerStateContext = React.createContext(initState)

/** @type {React.Context<ToastDispatch>} */
export const ToastReducerDispatchContext = React.createContext(() => undefined)

/** @type {(s: ToastReducerState, a: ToastReducerAction) => ToastReducerState} */
const reducer = (state, action) => {
  switch (action.type) {
    // if it is to add a message, first we check if the id is in the map
    case ToastReducerActionType.add: {
      // if already existed, update the message data with action payload
      if (state.messagesMap[action.toastId]) {
        return {
          ...state,
          messagesMap: {
            ...state.messagesMap,
            [action.toastId]: action.payload,
          },
        }
      }

      // only keep the max number of messages
      const takeLastIds = state.ids.slice(
        Math.max(state.ids.length - (state.max - 1), 0),
      )
      const messagesMap = {
        [action.toastId]: action.payload,
      }
      for (const id of takeLastIds) {
        messagesMap[id] = state.messagesMap[id]
      }

      return {
        ...state,
        ids: [...takeLastIds, action.toastId],
        messagesMap,
      }
    }

    case ToastReducerActionType.delete: {
      // if toast id is not defined, delete all messages
      if (typeof action.toastId === "undefined" || action.toastId === null) {
        return {
          ...state,
          ids: [],
          messagesMap: {},
        }
      }

      // remove the toastId from ids
      const removeIdIndex = state.ids.indexOf(action.toastId)
      if (removeIdIndex < 0) {
        return state
      }

      const ids = [
        ...state.ids.slice(0, removeIdIndex),
        ...state.ids.slice(removeIdIndex + 1),
      ]

      let messagesMap = {}
      for (const id of ids) {
        messagesMap[id] = state.messagesMap[id]
      }

      return {
        ...state,
        ids,
        messagesMap,
      }
    }
    default:
      return state
  }
}

export function ToastContextProvider({children}) {
  const [state, dispatch] = React.useReducer(reducer, initState)

  return (
    <ToastReducerStateContext.Provider value={state}>
      <ToastReducerDispatchContext.Provider value={dispatch}>
        {children}
      </ToastReducerDispatchContext.Provider>
    </ToastReducerStateContext.Provider>
  )
}

/**
 * @returns {ToastReducerState}
 */
export function useToastState() {
  const context = React.useContext(ToastReducerStateContext)

  return context
}

/**
 * @param {string} toastId
 * @returns {ToastMessage}
 */
export function useToastMessage(toastId) {
  const state = useToastState()

  return state.messagesMap[toastId]
}

/**
 * @returns {ToastDispatch}
 */
export function useToastDispatch() {
  const context = React.useContext(ToastReducerDispatchContext)

  return context
}

/**
 * @callback ToastFn
 * @param {string} message
 * @param {ToastMessage=} options
 * @returns {void}
 *
 * @returns {ToastFn}
 */
export function useToast() {
  const dispatch = useToastDispatch()
  return React.useCallback(
    (message, options) => toast(dispatch, message, options),
    [dispatch],
  )
}

/**
 * @param {ToastDispatch} dispatch
 * @param {string} message
 * @param {ToastMessage=} options
 */
export function toast(dispatch, message, options = {}) {
  const genToastId = () => `toast-${toastIdCounter++}`
  const {id = genToastId(), type = AlertType.warning, ...otherOptions} = options
  dispatch({
    type: ToastReducerActionType.add,
    toastId: id,
    payload: {
      ...otherOptions,
      message,
      id,
      type,
    },
  })
  return id
}

/**
 * @param {ToastDispatch} dispatch
 * @param {string} toastId
 */
export function remove(dispatch, toastId) {
  dispatch({
    type: ToastReducerActionType.delete,
    toastId,
  })
}

/**
 * @returns {(toastId: string) => void} toastId
 */
export function useRemove() {
  const dispatch = useToastDispatch()
  return React.useCallback(toastId => remove(dispatch, toastId), [dispatch])
}

/** @typedef {(action: ToastReducerAction) => void} ToastDispatch */

/** @typedef {AddToastReducerAction|DeleteToastReducerAction} ToastReducerAction */

/**
 * @typedef {Object} AddToastReducerAction
 * @property {"add"} type
 * @property {string} toastId
 * @property {ToastMessage} payload
 */

/**
 * @typedef {Object} DeleteToastReducerAction
 * @property {"delete"} type
 * @property {string} toastId
 */
