export const NO_INTERNET_MSG = "No Internet Connection"
export const REQUEST_TIMEOUT_MSG =
  "Request Timeout. Please check your network activity."

export const NO_INTERNET_CODE = "NetworkError"
export const REQUEST_TIMEOUT_CODE = "RequestTimeout"
export const REQUEST_TIMEOUT_CODE_AWS = "TimeoutError"

/**
 * @param {Error} error
 */
export function isNetworkError(error) {
  return error.code === NO_INTERNET_CODE
}
/**
 * @param {Error} error
 */
export function isRequestTimeout(error) {
  return (
    error.code === REQUEST_TIMEOUT_CODE ||
    error.name === REQUEST_TIMEOUT_CODE ||
    error.code === REQUEST_TIMEOUT_CODE_AWS
  )
}

/**
 * @param {Error} error
 */
export function defaultErrorTitle(error) {
  if (isNetworkError(error)) {
    return NO_INTERNET_MSG
  } else if (isRequestTimeout(error)) {
    // timeout error
    return REQUEST_TIMEOUT_MSG
  }
}

/**
 * @param {Error} err
 */
export function transformAmplifyNetworkError(err) {
  // in amplify, it returns NetworkError for different situation
  if (err.code === "NetworkError") {
    if (navigator?.onLine === false) {
      err.message = NO_INTERNET_MSG
    } else {
      err.code = REQUEST_TIMEOUT_CODE
      err.message = REQUEST_TIMEOUT_MSG
    }
  }
}
