import fetch, {throwRequestError} from "../fetch"
import {Auth} from "aws-amplify"

/**
 * @description fetch url with amplify get current session token
 * @template T
 * @param {string} uri ex: /something/workspace
 * @param {RequestConfig} requestConfig
 * @returns {Promise<import('../fetch').FetchResponse<T>>}
 */
export async function authoredFetch(uri, requestConfig = {}) {
  const cognitoSession = await Auth.currentSession()
  const token = cognitoSession.getAccessToken().getJwtToken()

  if (!token) {
    throwRequestError({
      status: 401,
      headers: {},
      statusText: "401",
      data: {
        errorMessage: "Not authorized",
      },
    })
  }

  const {headers = {}, ...configs} = requestConfig
  return fetch(uri, {
    ...configs,
    headers: {
      Authorization: token,
      ...headers,
    },
  })
}

export default authoredFetch

/**
 * @typedef {import('../fetch').RequestConfig} RequestConfig
 */
