import fetch from "../fetch"

import {getHeaders as composeAWSHeaders, getCredentials} from "./signature"

/**
 * @template T
 * @param {string} uri ex: /something/workspace
 * @param {RequestConfig} requestConfig
 * @returns {Promise<FetchResponse<T>>}
 */
export async function awsFetch(uri, requestConfig) {
  const credentials = await getCredentials()
  return fetch(uri, {
    ...requestConfig,
    getHeaders: param => composeAWSHeaders(param, credentials),
  })
}

export default awsFetch

/**
 * @typedef {import('../fetch').RequestConfig} RequestConfig
 * @typedef {import('../fetch').FetchResponse} FetchResponse
 */
