/**
 * @typedef {import('react-query/types/core/retryer').RetryValue<import('./types').CustomError>} RetryValue
 */

import {useToast} from "../../lib/toast"

import {signOut} from "../../lib/smartworks-api/auth"
import {removeUser} from "../../services/auth"

import {noop} from "../../utils/noop"
import {isNull} from "../../utils/is-null"
import {
  NO_INTERNET_MSG,
  REQUEST_TIMEOUT_MSG,
  isNetworkError,
  isRequestTimeout,
} from "../../utils/general-error"

/**
 * @param {RetryValue=} retry
 * @returns {RetryValue}
 */
export const defaultRetry = (retry, maxRetryCount = 2) => (
  failureCount,
  error,
) => {
  // if it is timeout or no network error, no retry
  if (isNetworkError(error) || isRequestTimeout(error)) {
    return false
  }

  if (error?.response?.status < 500) {
    return false
  }

  if (retry && typeof retry === "function" && retry.bind) {
    return retry(failureCount, error)
  }

  return failureCount < maxRetryCount
}

/**
 * @param {*} onError
 * @param {{
 *    errorMessage?: string | (err) => string,
 *    autoLogoutWhenAuthFail?: boolean
 *    networkToast?: boolean
 * }} param1
 */
export const useDefaultOnErrorHandler = (
  onError,
  {errorMessage, autoLogoutWhenAuthFail = true, networkToast = true} = {},
) => {
  const toast = useToast()

  return error => {
    let toasted = false

    if (networkToast && navigator.onLine === false) {
      // if no internet
      toast(NO_INTERNET_MSG, {
        type: "error",
        id: "network-error",
      })
      toasted = true
    } else if (
      networkToast &&
      (isNetworkError(error) || isRequestTimeout(error))
    ) {
      // timeout error
      toast(REQUEST_TIMEOUT_MSG, {
        type: "error",
        id: "network-error",
      })
      toasted = true
    } else if (errorMessage) {
      const msg =
        typeof errorMessage === "function" ? errorMessage(error) : errorMessage
      if (msg) {
        toast(msg, {
          type: "error",
        })
        toasted = true
      }
    }

    if (
      autoLogoutWhenAuthFail &&
      (error === "No current user" ||
        error?.response?.status === 401 ||
        error?.response?.status === 403)
    ) {
      signOut()
      removeUser()
      toast("Please re-authenticate.", {
        type: "error",
        id: "401Unauthorized",
      })
      toasted = true
      // refresh the page for them
      window.location.assign(window.location)
    }

    onError && onError(error, toasted ? noop : toast)
  }
}

/**
 * @param {*} onSuccess
 * @param {string | (data, variables, context) => string } successMessage
 */
export const useDefaultOnSuccessHandler = (onSuccess, successMessage) => {
  const toast = useToast()

  if (isNull(onSuccess) && isNull(successMessage)) return undefined

  return (...args) => {
    if (successMessage) {
      const msg =
        typeof successMessage === "function"
          ? successMessage(...args)
          : successMessage
      toast(msg, {
        type: "success",
      })
    }

    onSuccess && onSuccess(...args, toast)
  }
}
