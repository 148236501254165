import * as React from "react"
import {useTransition, animated} from "react-spring"

import {useToastState, useRemove} from "./toast-context"

/**
 * @typedef {import('./types').ToastMessage} ToastMessage
 * @typedef {import('./types').ToastMessageComponent} ToastMessageComponent
 */

/**
 *
 * @param {{
 *   ToastUI: ToastMessageComponent
 * }} param0
 */
export const ToastContainer = ({ToastUI = "div"}) => {
  let state = useToastState()
  const remove = useRemove()

  const transitions = useTransition(
    state.ids.map(id => state.messagesMap[id]),
    messageObj => messageObj.id,
    {
      from: {
        marginBottom: 10,
        transform: "translate3d(0%,-100%,0)",
        opacity: 0,
      },
      enter: {transform: "translate3d(0%, 0%, 0)", opacity: 1},
      leave: {
        transform: "translate3d(100%, 0%, 0)",
        opacity: 0.2,
      },
    },
  )

  return (
    <div
      style={{
        right: "50%",
        transform: "translateX(50%)",
        position: "fixed",
        top: 90,
        zIndex: 1350,
      }}>
      <div className="column">
        {transitions.map(({item: messageObj, props, key}) => (
          <animated.div key={key} style={props}>
            <ToastUI
              id={messageObj.id}
              type={messageObj.type}
              message={messageObj.message}
              onCloseClick={remove}
              autoClose={5000}
              onAutoClose={remove}
            />
          </animated.div>
        ))}
      </div>
    </div>
  )
}
