import AWS from "aws-sdk"
import hmacSHA256 from "crypto-js/hmac-sha256"
import sha256 from "crypto-js/sha256"
import {
  NO_INTERNET_CODE,
  NO_INTERNET_MSG,
  REQUEST_TIMEOUT_CODE,
  REQUEST_TIMEOUT_MSG,
} from "../../utils/general-error"

import {REGION, HOST, accessKey, secretAccessKey} from "./env"
const ALGORITHM = "AWS4-HMAC-SHA256"
const SERVICE = "execute-api"
const TERMINATION = "aws4_request"
const AWS_CONFIG_EXPIRY_WINDOW = 60000

/**
 * @returns {Promise<AWS.Credentials | import("aws-sdk/lib/credentials").CredentialsOptions>}
 */
export async function getCredentials() {
  if (
    AWS.config &&
    AWS.config.credentials &&
    AWS.config.credentials.expireTime &&
    AWS.config.credentials.sessionToken &&
    AWS.config.credentials.expireTime.valueOf() >
      Date.now() + AWS_CONFIG_EXPIRY_WINDOW &&
    !AWS.config.credentials.expired
  ) {
    return Promise.resolve(AWS.config.credentials)
  }

  // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS.html#config-property
  AWS.config = new AWS.Config()
  // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Config.html#update-property
  AWS.config.update({
    accessKeyId: accessKey,
    secretAccessKey: secretAccessKey,
    httpOptions: {
      timeout: 30000,
    },
  })

  return new Promise((resolve, reject) => {
    // https://docs.aws.amazon.com/STS/latest/APIReference/API_GetSessionToken.html
    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/STS.html#getSessionToken-property
    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/STS.html#credentialsFrom-property
    var sts = new AWS.STS()
    sts.getSessionToken({}, function (error, data) {
      if (error) {
        if (navigator?.onLine === false) {
          error.code = NO_INTERNET_CODE
          error.message = NO_INTERNET_MSG
        } else if (error.code === "TimeoutError") {
          error.name = REQUEST_TIMEOUT_CODE
          error.code = REQUEST_TIMEOUT_CODE
          error.message = REQUEST_TIMEOUT_MSG
        }

        // console.log(error, error.stack)
        reject(error)
      } else {
        // console.log(data)
        AWS.config.credentials = sts.credentialsFrom(data)
        resolve(AWS.config.credentials)
      }
    })
  })
}

// https://docs.aws.amazon.com/general/latest/gr/sigv4-signed-request-examples.html
// https://docs.aws.amazon.com/AmazonECR/latest/APIReference/CommonParameters.html
// https://docs.aws.amazon.com/general/latest/gr/signature-version-4.html
/**
 * @param {{
 *   method: Method,
 *   host?: string,
 * . uri: string,
 * . params?: string,
 *   body?: string,
 * }} param0
 * @param {AWS.Credentials | import("aws-sdk/lib/credentials").CredentialsOptions} credentials
 */
export function getHeaders(param0 = {}, credentials) {
  const {method = "GET", host = HOST, uri = "", params = "", body = ""} = param0

  let access_key = credentials.accessKeyId
  let secret_key = credentials.secretAccessKey
  let security_token = credentials.sessionToken

  let amzdate =
    new Date()
      .toISOString()
      .slice(0, 19)
      .replace(/-|:\s*/g, "") + "Z"
  let datestamp = amzdate.substr(0, 8)

  /** TASK 1: CREATE A CANONICAL REQUEST */
  let canonical_querystring = params
  let canonical_headers =
    "host:" +
    host +
    "\n" +
    "x-amz-date:" +
    amzdate +
    "\n" +
    "x-amz-security-token:" +
    security_token +
    "\n"
  let signed_headers = "host;x-amz-date;x-amz-security-token"
  let payload_hash = sha256(body)

  let canonical_request =
    method +
    "\n" +
    uri +
    "\n" +
    canonical_querystring +
    "\n" +
    canonical_headers +
    "\n" +
    signed_headers +
    "\n" +
    payload_hash
  //console.log('TASK 1: canonical_request')
  //console.log(canonical_request)

  /** TASK 2: CREATE THE STRING TO SIGN */
  let credential_scope =
    datestamp + "/" + REGION + "/" + SERVICE + "/" + TERMINATION
  let string_to_sign =
    ALGORITHM +
    "\n" +
    amzdate +
    "\n" +
    credential_scope +
    "\n" +
    sha256(canonical_request)
  //console.log('TASK 2: string_to_sign')
  //console.log(string_to_sign)

  /** TASK 3: CALCULATE THE SIGNATURE */
  var signing_key = getSignatureKey(secret_key, datestamp, REGION, SERVICE)
  var signature = hmacSHA256(string_to_sign, signing_key)
  // console.log("TASK 3: signature")
  // console.log("" + signing_key)
  //console.log('' + signature)

  /** TASK 4: ADD SIGNING INFORMATION TO THE REQUEST */
  var authorization_header =
    ALGORITHM +
    " " +
    "Credential=" +
    access_key +
    "/" +
    credential_scope +
    ", " +
    "SignedHeaders=" +
    signed_headers +
    ", " +
    "Signature=" +
    signature

  // console.log("TASK 4: authorization_header")
  // console.log(authorization_header)

  return {
    "x-amz-date": amzdate,
    "x-amz-security-token": security_token,
    authorization: authorization_header,
    "Content-Type": "application/json",
  }
}

function getSignatureKey(key, dateStamp, regionName, serviceName) {
  var kDate = hmacSHA256(dateStamp, "AWS4" + key)
  var kRegion = hmacSHA256(regionName, kDate)
  var kService = hmacSHA256(serviceName, kRegion)
  var kSigning = hmacSHA256(TERMINATION, kService)
  return kSigning
}

/** @typedef {import('axios').Method} Method */
