import * as React from "react"

import {IconButton} from "./icon-button"
import "./toast-message.scss"

const AlertType = {
  success: "success",
  error: "error",
  warning: "warning",
}

/**
 * @typedef {object} ToastMessageOwnProps
 * @property {keyof AlertType} type
 * @property {string} id
 * @property {string} message
 * @property {(id: string) => void=} onCloseClick
 * @property {number=} autoClose - close after certain ms, 0 => no auto close
 * @property {(id: string) => void=} onAutoClose - the function call that got fired when auto close timer is fired
 *
 * @typedef {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} RawDivProps
 * @typedef {RawDivProps & ToastMessageOwnProps} ToastMessageProps
 */

/**
 * @type {React.FunctionComponent<ToastMessageProps>}
 * @param {ToastMessageProps} param0
 */
export const ToastMessage = ({
  type = AlertType.error,
  id,
  message,
  className,
  onCloseClick,
  autoClose = 0,
  onAutoClose,
  ...divProps
}) => {
  // set auto close timer
  const timeOutIdRef = React.useRef(undefined)
  React.useEffect(() => {
    if (autoClose) {
      let timeOutId = setTimeout(() => {
        onAutoClose && onAutoClose(id)
      }, autoClose)
      timeOutIdRef.current = timeOutId
      return () => {
        clearTimeout(timeOutId)
      }
    }
  }, [autoClose, onAutoClose, id])

  return (
    <div
      className={`component-base-toast-message-container ${className || ""}`}
      {...divProps}>
      <div className="toast-message-type-img-container column-center">
        <img
          // className="toast-message-type-img"
          src={require(`../../images/ic-${type}.svg`)}
          alt={type}
          width={30}
          height={30}
        />
      </div>
      <div className="toast-message-separator" />
      <div className="toast-message-content-container">
        <h1 className="text-box-label">{type}</h1>
        <div>{message}</div>
      </div>
      <div className="toast-message-close-container column-center">
        <IconButton
          icon="btn-close"
          aria-label="close this warning"
          size={20}
          onClick={() => onCloseClick && onCloseClick(id)}
        />
      </div>
    </div>
  )
}
